import { Routes } from '@angular/router';


export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
    }, 
    {
        path: 'sitemap/:id',
        
        loadComponent: () => import('./rawpath/rawpath.component').then((m) => m.RawpathComponent),
    },

   
    {
        path: 'category/:cat/:level/:brickid',
        
        loadComponent: () => import('./category/category.component').then((m) => m.CategoryComponent),
    },
    {
        path: 'products/product-detail/01/:gtin',
        loadComponent: () => import('./product-detail/product-detail.component').then((m) => m.ProductDetailComponent),
    },
    {
        path: 'product/all',
        
        loadComponent: () => import('./all-products/all-products.component').then((m) => m.AllProductsComponent),
    },

    { path: '**', redirectTo: '/' },
];
