import { Component, OnInit, afterNextRender, afterRender } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ScrollService } from './services/scroll.service';
import { Observable, fromEvent, map, startWith, distinctUntilChanged, shareReplay } from 'rxjs';


@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [CommonModule,RouterOutlet

  ]
})
export class AppComponent  {
  windowScroll$!: Observable<any>;
  constructor(private scrollService:ScrollService) {
    afterNextRender(() => {

      this.windowScroll$ = fromEvent(window, 'scroll').pipe(
        map(event => window.scrollY), // Extract scrollY value
        startWith(0), // Provide initial value (optional)
        distinctUntilChanged(), // Emit only if value changes
        shareReplay(1) // Share single subscription across components
      );

      setTimeout(() => {
        this.windowScroll$ && this.windowScroll$.subscribe(scrollY => {
          // console.log("Y change ..................")
         scrollService.setScrollPos(scrollY);
          // Perform actions based on scroll position
        });
       }, 2000);

    });

  }
 
}
